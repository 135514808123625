import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { light } from "../../../config/Themes";
import { useDispatch } from "react-redux";
import { addToCart } from "../../Cart/state/reducers";

const Card = styled.div`
  width: 30%;
  border: 1px solid rgba(${(props) => props.theme.textRgba}, 0.1);
  border-radius: ${(props) => props.theme.smallRadius};
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Name = styled.h4`
  font-family: "Tajawal", sans-serif;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text};
`;

const Brand = styled.span`
  font-family: "Tajawal", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.secondaryText};
`;

const Desc = styled.p`
  max-width: 75%;
  font-family: "Tajawal", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  color: ${(props) => props.theme.text};
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  color: ${(props) => props.theme.primary};
`;

const Price = styled.h4`
  font-size: 1.4rem;
`;

const Product = ({ product }) => {
  const { i18n } = useTranslation();
  const { name, sellingPrice } = product;

  return (
    <Card isArabic={i18n.language === "ar"}>
      <Body>
        <ContentRow>
          <Name>
            {name} - <Brand>{product.brand}</Brand>
          </Name>
        </ContentRow>
        <ContentRow>
          <Desc>{product.description}</Desc>
          <PriceContainer>
            <Price>
              {sellingPrice} {t("dzd")}
            </Price>
          </PriceContainer>
        </ContentRow>
      </Body>
    </Card>
  );
};

export default Product;
